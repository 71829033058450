const showroomsData = [
  // SHOWROOMS TAB DATA STARTS
  {
    category: {
      "en":"Showrooms",
      "ar": "صالات العرض",
    }, 
    cities: [
      {
        name: "Riyadh",
        showrooms: [
          {
            locationName: {
              en:"Riyadh (Khurais Road Al Nahda)",
              ar:"الرياض (طريق خريص النهضة)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              sundayToThursday: ["8:00 am - 9:00 pm"],
              saturday: ["9:00 am - 12:00 pm", "4:00 pm - 9:00 pm"],
              friday: ["4:00 pm - 9:00 pm"],
            },
            address:
              "Najeeb Auto, SUZUKI, Near STC, Khurais Rd, Al Nahda, Riyadh 13221",
            mapLink: "https://maps.app.goo.gl/ickq8erumLt3W73T9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14493.339572332403!2d46.8169555!3d24.7497043!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f012467eb6bc3%3A0xed73963798b97a14!2sNajeeb%20Auto%2C%20SUZUKI!5e0!3m2!1sen!2ssa!4v1720965518467!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Jeddah",
        showrooms: [
          {
            locationName: {
              en:"Jeddah (Al Madinah Road)",
              ar:"جدة (طريق المدينة)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              sundayToThursday: ["8:00 am - 9:00 pm"],
              saturday: ["9:00 am - 12:00 pm", "4:00 pm - 9:00 pm"],
              friday: ["4:00 pm - 9:00 pm"],
            },
            address:
              "Najeeb Auto, 7497 2110 Al-Madinah Al-Munawarah Rd, Al Bawadi, Jeddah 23531",
            mapLink: "https://maps.app.goo.gl/GGsiVPM6dNeVaFpK8",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d927.3380327410101!2d39.156498!3d21.611198!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d124a89eec93%3A0xefad82e07f86f431!2sNajeeb%20Auto!5e0!3m2!1sen!2ssa!4v1721024930810!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Al Khobar",
        showrooms: [
          {
            locationName: {
              en:"Khobar (Custodian of The Two Holy Mosques Road)",
              ar:"الخبر (طريق خادم الحرمين الشريفين)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              sundayToThursday: ["8:00 am - 9:00 pm"],
              saturday: ["9:00 am - 12:00 pm", "4:00 pm - 9:00 pm"],
              friday: ["4:00 pm - 9:00 pm"],
            },
            address:
              "Najeeb Auto, Custodian of The Two Holy Mosques Rd Madinat Al Umal Al Khobar 34443",
            mapLink: "https://maps.app.goo.gl/4RAxcdJTXLTAv4ef9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1788.656428350191!2d50.19908!3d26.283956!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e9c6197f90b3%3A0x89d0263a61fb362f!2zTmFqZWViIEF1dG8sIFNVWlVLSSB8INmG2KzbjNioINij2YjYqtmILCDYs9mI2LLZiNmD2YrigI4!5e0!3m2!1sen!2ssa!4v1721025108561!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Madinah",
        showrooms: [
          {
            locationName: {
              en:"Medina Showroom",
              ar:"معرض المدينة",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              sundayToThursday: ["8:00 am - 9:00 pm"],
              saturday: ["9:00 am - 12:00 pm", "4:00 pm - 9:00 pm"],
              friday: ["4:00 pm - 9:00 pm"],
            },
            address: "Najeeb Auto, Airport Rd Ad Duwaikhilah Madinah 42365",
            mapLink: "https://maps.app.goo.gl/Zf7Wbotc6ZwsDPzq7",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d453.77048897608506!2d39.664661!3d24.514410000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15bd97eb27fb7ee7%3A0xa867e8efab970a17!2z2YbYrNmK2Kgg2KPZiNiq2YggLSDYs9mI2LLZiNmD2YogfFN1enVraSBOYWplZWIgQXV0b-KAjg!5e0!3m2!1sen!2ssa!4v1721025239611!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Al Ahsa",
        showrooms: [
          {
            locationName: {
              en:"Al Ahsa Showroom",
              ar:"معرض الأحساء",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              sundayToThursday: ["8:00 am - 9:00 pm"],
              saturday: ["9:00 am - 12:00 pm", "4:00 pm - 9:00 pm"],
              friday: ["4:00 pm - 9:00 pm"],
            },
            address: "Najeeb Auto, Al Suwaidirah, Al Hofuf 36364",
            mapLink: "https://maps.app.goo.gl/ENqZrr5cGPRKvtHLA",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3604.85648282408!2d49.621794!3d25.376126!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e379596b0f8cbe7%3A0x2288f207b21477d9!2zTmFqZWViIEF1dG8sIFNVWlVLSSB8INmG2KzbjNioINij2YjYqtmILCDYs9mI2LLZiNmD2YrigI4!5e0!3m2!1sen!2ssa!4v1721025340920!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Al Kharj",
        showrooms: [
          {
            locationName: {
              en:"Al Kharj Showroom",
              ar:"معرض الخرج",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              sundayToThursday: ["8:00 am - 9:00 pm"],
              saturday: ["9:00 am - 12:00 pm", "4:00 pm - 9:00 pm"],
              friday: ["4:00 pm - 9:00 pm"],
            },
            address:
              "OKUB5152, 5152 King Abdullah Road, 7539، حي المنصورة، Al-Kharj 16285",
            mapLink: "https://maps.app.goo.gl/xuzL1EcDXrVSSgut9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d227.4893212014145!2d47.34519005360235!3d24.177724281856356!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e254e58c6b36817%3A0xa3c82efac1123173!2zT0tVQjUxNTIsIDUxNTIgS2luZyBBYmR1bGxhaCBSb2FkLCA3NTM52Iwg2K3ZiiDYp9mE2YXZhti12YjYsdip2IwgQWwtS2hhcmogMTYyODU!5e0!3m2!1sen!2ssa!4v1721025465502!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Khamis Mushait",
        showrooms: [
          {
            locationName: {
              en:"Khamis Mushait Showroom",
              ar:"معرض خميس مشيط",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              sundayToThursday: ["8:00 am - 9:00 pm"],
              saturday: ["8:00 am - 9:00 pm"],
              friday: ["4:00 pm - 9:00 pm"],
            },
            address: "طريق الملك سعود, Al Fath, Khamis Mushait 62481",
            mapLink: "https://maps.app.goo.gl/fLeCwth5kAMW8X9Y7",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d7574.831582496074!2d42.73255600000001!3d18.328306!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDE5JzQxLjkiTiA0MsKwNDMnNTcuMiJF!5e0!3m2!1sen!2ssa!4v1721025589052!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Najran",
        showrooms: [
          {
            locationName: {
              en:"Najran Showroom",
              ar:"معرض نجران",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              sundayToThursday: ["8:00 am - 9:00 pm"],
              saturday: ["8:00 am - 9:00 pm"],
              friday: ["4:00 pm - 9:00 pm"],
            },
            address: "5083 King Abdulaziz Rd, 9692، Najran 66241",
            mapLink: "https://maps.app.goo.gl/ZQpRW1mZEGdbT8PK8",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d7608.195296748841!2d44.287345!3d17.550537!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDMzJzAxLjkiTiA0NMKwMTcnMjMuNyJF!5e0!3m2!1sen!2ssa!4v1721025686956!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Onaiza",
        showrooms: [
          {
            locationName: {
              en:"Onaiza Showroom",
              ar:"معرض عنيزة",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              sundayToThursday: ["9:00 am - 9:00 pm"],
              saturday: ["9:00 am - 9:00 pm"],
              friday: ["2:00 pm - 9:00 pm"],
            },
            address:
              "QAGB3630، 3630 طريق السفير الشبيلي، 6099، حي المنار, Unayzah 56439",
            mapLink: "https://maps.app.goo.gl/3qeai4Bb2NRDnQhP9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d7166.3775970954075!2d44.020647!3d26.092755000000004!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDA1JzMzLjkiTiA0NMKwMDEnMjMuNiJF!5e0!3m2!1sen!2ssa!4v1721025801790!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Mecca",
        showrooms: [
          {
            locationName: {
              en:"Mecca Showroom",
              ar:"معرض مكة",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              sundayToThursday: ["8:00 am - 9:00 pm"],
              saturday: ["9:00 am - 12:00 pm", "4:00 pm - 9:00 pm"],
              friday: ["4:00 pm - 9:00 pm"],
            },
            address:
              "Najeeb Auto, SUZUKI, نجيب أوتو - سوزكى Al Lith Rd, Walyal Ahd Dist., Makkah 24354",
            mapLink: "https://maps.app.goo.gl/v5qMPAxsXRuWo8sw5",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3715.635352547891!2d39.7978591!3d21.364873799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c211369ab638f1%3A0xdca417782cd4ec70!2sNajeeb%20Auto%2C%20SUZUKI!5e0!3m2!1sen!2ssa!4v1724316376638!5m2!1sen!2ssa",
          },

          
        ],
      },
      // More cities...
    ],
  },
  // SHOWROOMS TAB DATA ENDS

  // SERVICE CENTERS TAB DATA STARTS
  {
    category: {
      "en":"Service Centers",
      "ar":"مراكز الخدمة",
    },
    cities: [
      {
        name: "Riyadh",
        showrooms: [
          {
            locationName: {
              en:"Al Riyadh (Exit 18)",
              ar:"الرياض (مخرج 18)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 5:00 pm"],
              break: ["12:00 pm - 1:00 pm"],
              friday: ["Closed"],
            },
            address:
              "Suzuki Service Center & Spare Parts Najeeb Auto JQRV+RH, Al Faisaliyyah, Riyadh 12894",
            mapLink: "https://maps.app.goo.gl/Cgte79CJuDVHqYJa6",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d453.3081168276063!2d46.793876!3d24.64212!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0797e5f82d97%3A0xc9583fb10663dda0!2sSuzuki%20Service%20Center%20%26%20Spare%20Parts%20Najeeb%20Auto!5e0!3m2!1sen!2ssa!4v1721026084777!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Riyadh, Riyadh (Anas Ibn Malik)",
              ar:"الرياض (أنس بن مالك)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 11:00 pm"],
              break: ["12:00 pm - 1:00 pm"],
              friday: ["2:00 pm - 12:00 am"],
            },
            address:
              "Suzuki service center RANB4234، 8791 No. 254، حي النرجس، 4234, Riyadh 13324",
            mapLink: "https://maps.app.goo.gl/xea3zjuwg8M7cEbq9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1810.4174515201025!2d46.683846!3d24.835319!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efb18fcaec321%3A0x603f0f697b140554!2sSuzuki%20service%20center!5e0!3m2!1sen!2ssa!4v1721026202088!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Jeddah",
        showrooms: [
          {
            locationName: {
              en:"Jeddah (Palestine Street, Rehab)",
              ar:"جدة (شارع فلسطين، حي الرهب)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 5:00 pm"],
              break: ["12:00 pm - 1:00 pm"],
              friday: ["Closed"],
            },
            address:
              "حي الرحاب، JCRA3986، 3986 فلسطين الفرعي، 6403, Jeddah 23343",
            mapLink: "https://maps.app.goo.gl/ZAegyKF6YoctVqy8A",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d611.2869602976247!2d39.2211819!3d21.5385434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d171e25372d5%3A0xacfc4f3e2ee667c8!2z2LXZitin2YbYqSDZhtis2YrYqCDYp9mI2KrZiCDYs9mI2LLZiNmD2Yo!5e1!3m2!1sen!2ssa!4v1721808782734!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Al Khobar",
        showrooms: [
          {
            locationName: {
              en:"Al Khobar (Sinayiat Al Thuqba)",
              ar:"الخبر (صناعية الثقبة)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 5:00 pm"],
              break: ["12:00 pm - 1:00 pm"],
              friday: ["Closed"],
            },
            address: "752X+56F, صناعية, الثقبة, Al Khobar 34626",
            mapLink: "https://maps.app.goo.gl/Kr3t28QZjHPYvrmJA",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4715.215884039486!2d50.198035999999995!3d26.250442999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49c3d7de7358cb%3A0xde253ad4d624be15!2sSUZUKI!5e1!3m2!1sen!2ssa!4v1721809342568!5m2!1sen!2ssa",
          },
        ],
      },

      {
        name: "Al Ahsa",
        showrooms: [
          {
            locationName: {
              en:"Al Ahsa Service Center(Al Rashdiyah)",
              ar:"مركز خدمة الأحساء (الرشدية)",
            } ,
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 12:00 am"],
              break: ["1:00 pm - 2:00 pm"],
              friday: ["2:00 pm - 12:00 am"],
            },
            address: "صيانة وقطع الغيار نجيب اوتو سوزوكي CHMR+PFF, Al Rashdiyah, Al Mubarraz 36342",
            mapLink: "https://maps.app.goo.gl/7F6b1qwUDCaHrgNb7",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d18991.15894144232!2d49.567799!3d25.436066!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e3797e5e1388061%3A0x4f946a79cc7fd560!2z2LXZitin2YbYqSDZiNmC2LfYuSDYp9mE2LrZitin2LEg2YbYrNmK2Kgg2KfZiNiq2Ygg2LPZiNiy2YjZg9mK!5e1!3m2!1sen!2ssa!4v1721809408000!5m2!1sen!2ssa",
          },
        ],
      },
      // More cities...
    ],
  },
  // SERVICE CENTERS TAB DATA ENDS

  // WATANI EXPRESS TAB DATA STARTS
  {
    category: {
      "en":"Watani Express",
      "ar":"وطني إكسبريس"
    }, 
    cities: [
      {
        name: "Riyadh",
        showrooms: [
          {
            locationName: {
              en:"Watani Express, Al Aarid",
              ar:"وطني إكسبريس، العريض",
            }, 
            telephone: ["8001240484"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 11:00 pm"],
              break: ["12:00 pm - 1:00 pm"],
              friday: ["2:00 pm - 12:00 am"],
            },
            address: "6506 حي, العارض، RRRA3724، 3724 الأمير خالد بن بندر بن عبدالعزيز, Riyadh 13338",
            mapLink: "https://maps.app.goo.gl/k1ZPwZGfam2aMwg89",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d298.11105198506215!2d46.6273767!3d24.8723325!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee51f36ae8b25%3A0x9a3bb95f9623287a!2zV2F0YW5pIEV4cHJlc3Mg2YjYt9mG2Yog2KXZg9iz2KjYsdiz!5e1!3m2!1sen!2ssa!4v1721809537109!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Riyadh (Anas Ibn Malik)",
              ar:"الرياض (أنس بن مالك)",
            }, 
            telephone: ["8001240484"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 11:00 pm"],
              break: ["12:00 pm - 1:00 pm"],
              friday: ["2:00 pm - 12:00 am"],
            },
            address:
              "Watani Express وطني إكسبرس Anas Ibn Malik Rd, An Narjis, Riyadh 13324",
            mapLink: "https://maps.app.goo.gl/hnG1v6yiAbKKwNve9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d905.2083601634233!2d46.684235!3d24.835369!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efb60c73f5fc5%3A0x5caa5279c2cbba3a!2zV2F0YW5pIEV4cHJlc3Mg2YjYt9mG2Yog2KXZg9iz2KjYsdiz!5e0!3m2!1sen!2ssa!4v1721027775454!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Dammam",
        showrooms: [
          {
            locationName: {
              en:"Watani Express, Dammam",
              ar:"وطني إكسبريس، الدمام",
            }, 
            telephone: ["8001240484"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 12:00 pm"],
              break: ["1:00 pm - 2:00 pm"],
              friday: ["02:00 pm - 12:00 am"],
            },
            address: "3382 ابو عبيدة عامر بن الجراح،, 8360،, حي المنار، EDSF3382،, Dammam 32275",
            mapLink: "https://maps.app.goo.gl/4L4SRVfJ647pQMKR7",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4710.722862061758!2d50.04180859999999!3d26.360934300000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e3c20d4cf633%3A0xc3f4f7d196a65733!2sWatani%20Express%20Quick%20Lube!5e1!3m2!1sen!2ssa!4v1721809619020!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Al Ahsa",
        showrooms: [
          {
            locationName: {
              en:"Al Ahsa,Service Center(Al Rashdiyah)",
              ar: "مركز خدمة الأحساء (الرشدية)",
            }, 
            telephone: ["8001240484"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 12:00 am"],
              break: ["1:00 pm - 2:00 pm"],
              friday: ["2:00 pm - 12:00 am"],
            },
            address: "CHMR+MMP، طريق الأمير طلال, Al Rashdiyah, Al Mubarraz 36342",
            mapLink: "https://maps.app.goo.gl/a7fBE6ExKc3p7FFR6",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4747.864239135392!2d49.589002!3d25.4341755!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e37979ba66c9955%3A0x21c556ffc46d4287!2zV2F0YW5pIEV4cHJlc3Mg2YjYt9mG2Yog2KXZg9iz2KjYsdiz!5e1!3m2!1sen!2ssa!4v1721809714141!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },
      // More cities...
    ],
  },
  // WATANI EXPRESS TAB DATA ENDS

  // SPARE PARTS TAB DATA STARTS
  {
    category: {
      "en":"Spare Parts",
      "ar":"قطع الغيار",
    }, 
    cities: [
      {
        name: "Riyadh",
        showrooms: [
          {
            locationName: {
              en:"Riyadh (Khurais Road Al Nahda)",
              ar:"الرياض (طريق خريص النهضة)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 5:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address:
              "Najeeb Auto, SUZUKI, Near STC, Khurais Rd, Al Nahda, Riyadh 13221",
            mapLink: "https://maps.app.goo.gl/ickq8erumLt3W73T9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14493.339572332403!2d46.8169555!3d24.7497043!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f012467eb6bc3%3A0xed73963798b97a14!2sNajeeb%20Auto%2C%20SUZUKI!5e0!3m2!1sen!2ssa!4v1720965518467!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Al Riyadh (Exit 18)",
              ar:"الرياض (مخرج 18)",

            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 5:00 pm"],
              break: ["12:00 pm - 1:00 pm"],
              friday: ["Closed"],
            },
            address:
              "Suzuki Service Center & Spare Parts Najeeb Auto JQRV+RH, Al Faisaliyyah, Riyadh 12894",
            mapLink: "https://maps.app.goo.gl/e6Kd6uVcRAuUBtzM6",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d226.65412918520838!2d46.793925!3d24.642081!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0797e5f82d97%3A0xc9583fb10663dda0!2sSuzuki%20Service%20Center%20%26%20Spare%20Parts%20Najeeb%20Auto!5e0!3m2!1sen!2ssa!4v1721028410511!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Jeddah",
        showrooms: [
          {
            locationName: {
              en:"Jeddah (Palestine Street)",
              ar:"جدة (شارع فلسطين)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 5:00 pm"],
              break: ["12:00 pm - 1:00 pm"],
              friday: ["Closed"],
            },
            address: "حي الرحاب، JCRA3986، 3986 فلسطين الفرعي، 6403, Jeddah 23343",
            mapLink: "https://maps.app.goo.gl/ZAegyKF6YoctVqy8A",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d611.2869602976247!2d39.2211819!3d21.5385434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d171e25372d5%3A0xacfc4f3e2ee667c8!2z2LXZitin2YbYqSDZhtis2YrYqCDYp9mI2KrZiCDYs9mI2LLZiNmD2Yo!5e1!3m2!1sen!2ssa!4v1721808782734!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Jeddah (Al Madinah Road)",
              ar:"جدة (طريق المدينة)",
            },
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 5:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address:
              "7497 2110 Al-Madinah Al-Munawarah Rd, Al Bawadi, Jeddah 23531, Saudi Arabia",
            mapLink: "https://maps.app.goo.gl/9TXJg92KUgL5ThDU9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d927.3423888490572!2d39.1564222!3d21.6105186!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d124a89eec93%3A0xefad82e07f86f431!2sNajeeb%20Auto!5e0!3m2!1sen!2ssa!4v1721028638735!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Al Khobar",
        showrooms: [
          {
            locationName: {
              en:"Al Khobar (Sinayiat Al Thuqba)",
              ar:"الخبر (صناعية الثقبة)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 5:00 pm"],
              break: ["12:00 pm - 1:00 pm"],
              friday: ["Closed"],
            },
            address: "752X+56F, صناعية, الثقبة, Al Khobar 34626",
            mapLink: "https://maps.app.goo.gl/Kr3t28QZjHPYvrmJA",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4715.215884039486!2d50.198035999999995!3d26.250442999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49c3d7de7358cb%3A0xde253ad4d624be15!2sSUZUKI!5e1!3m2!1sen!2ssa!4v1721808965733!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Khobar (Madinat Al Umal)",
              ar:"الخبر (مدينة العمال)",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 5:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address:
              "Najeeb Auto, SUZUKI | نجیب أوتو, سوزوكي‎ Custodian of The Two Holy Mosques Rd, Madinat Al Umal, Al Khobar 34443",
            mapLink: "https://maps.app.goo.gl/tQ7bGfi8iMdCErE99",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d223.5819398359539!2d50.198994!3d26.284015!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e9c6197f90b3%3A0x89d0263a61fb362f!2zTmFqZWViIEF1dG8sIFNVWlVLSSB8INmG2KzbjNioINij2YjYqtmILCDYs9mI2LLZiNmD2YrigI4!5e0!3m2!1sen!2ssa!4v1721028853735!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Madinah",
        showrooms: [
          {
            locationName: {
              en:"Madina- Showroom, Spare Parts",
              ar:"المدينة - معرض، قطع غيار",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 5:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address:
              "نجيب أوتو - سوزوكي |Suzuki Najeeb Auto‎ Airport Rd Ad Duwaikhilah Madinah 42365",
            mapLink: "https://maps.app.goo.gl/TFuMHaQZCCBHFNmw8",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d453.7703661795101!2d39.664657!3d24.514444!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15bd97eb27fb7ee7%3A0xa867e8efab970a17!2z2YbYrNmK2Kgg2KPZiNiq2YggLSDYs9mI2LLZiNmD2YogfFN1enVraSBOYWplZWIgQXV0b-KAjg!5e0!3m2!1sen!2ssa!4v1721029032089!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Al Ahsa",
        showrooms: [
          {
            locationName: {
              en:"Al Ahsa Showroom",
              ar:"معرض الأحساء",
            }, 
            telephone: ["8002444441"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 5:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address: "Najeeb Auto, Al Suwaidirah, Al Hofuf 36364",
            mapLink: "https://maps.app.goo.gl/ENqZrr5cGPRKvtHLA",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3604.85648282408!2d49.621794!3d25.376126!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e379596b0f8cbe7%3A0x2288f207b21477d9!2zTmFqZWViIEF1dG8sIFNVWlVLSSB8INmG2KzbjNioINij2YjYqtmILCDYs9mI2LLZiNmD2YrigI4!5e0!3m2!1sen!2ssa!4v1721025340920!5m2!1sen!2ssa",
          },
        ],
      },
      // More cities...
    ],
  },
  // SPARE PARTS TAB DATA ENDS

  // SERVICE PROVIDERS TAB DATA STARTS
  {
    category: {
      "en":"Service Providers",
      "ar":"مقدمو الخدمة",
    },
    cities: [
      // {
      //   name: "Jeddah",
      //   showrooms: [
      //     {
      //       locationName: "Jeddah, Corniche",
      //       telephone: ["0563697374"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address: "G583+XM2, Al Mansourah, Al-Hamra'a, Jeddah 23212",
      //       mapLink: "https://maps.app.goo.gl/3N88teZ4hLBLuPQu6",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1100.7181121188764!2d39.1533483!3d21.5174332!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3c562ab2fc71d%3A0x8974783cb6c7e2f9!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721752361817!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Jeddah, Amir Mitab St.",
      //       telephone: ["0581673600", "0591236154"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address:
      //         "H6H5+8MM, Prince Mutaib bin Abdulaziz Rd, Al-Safa, Jeddah 23454",
      //       mapLink: "https://maps.app.goo.gl/4hNDScZk7dKokav27",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1100.2531843125087!2d39.2083324!3d21.5787329!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d10ee261eae7%3A0x1ed25383eaa7738d!2sOne%20Stop%20-%20Fuchs!5e1!3m2!1sen!2ssa!4v1721752584724!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Jeddah, Makarona",
      //       telephone: ["0596311422"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address:
      //         "6551 المكرونة, Mishrifah District، حي مشرفة,, Jeddah 23335",
      //       mapLink: "https://maps.app.goo.gl/SkeVd9KAuibNDUiQ9",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4402.354760344822!2d39.1983728!3d21.5345139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3ce2a842dac9d%3A0x2678ec66db82f379!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721752753369!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Jeddah, Obhur (al Bandr Dist)",
      //       telephone: ["0572585543", "0565047745"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address:
      //         "Q459+5HW, Aabir Al Qarath St, Obhur Al-Shamaliyah, Jeddah 23815",
      //       mapLink: "https://maps.app.goo.gl/ANZ2s19jBjrdwo8NA",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1098.8875902979005!2d39.1183358!3d21.7578324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c1630236dff215%3A0xcac6e01c93d06cac!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721752976231!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Jeddah, Haraj Sayarah",
      //       telephone: ["0509599104", "0593005877"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address: "7986, 3898, Al Jawharah District, Jeddah 22417",
      //       mapLink: "https://maps.app.goo.gl/SkeVd9KAuibNDUiQ9",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4402.354760344822!2d39.1983728!3d21.5345139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3ce2a842dac9d%3A0x2678ec66db82f379!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721752753369!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Jeddah, Sanabel",
      //       telephone: ["0597186200"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address: "خالد بن خويلد الهذلي, Al Sanabel, Jeddah 22434",
      //       mapLink: "https://maps.app.goo.gl/MqMGboHjdb8nptQ5A",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3714.845175797665!2d39.2681389!3d21.396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDIzJzQ1LjYiTiAzOcKwMTYnMDUuMyJF!5e0!3m2!1sen!2ssa!4v1721753231522!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Jeddah, Bawadi",
      //       telephone: ["0596079224"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address: "3561، السنابل، جدة 22434 6742،",
      //       mapLink: "https://maps.app.goo.gl/aJ4Cm8tDoYhoboXP8",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d232.17782571018986!2d39.268043399999996!3d21.3959986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3cbd25d9fe745%3A0xa4c20620ccd2cc7d!2z2YHZiNmD2LMg2YjZhiDYs9iq2YjYqA!5e0!3m2!1sen!2ssa!4v1721754541308!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Jeddah, Sitten",
      //       telephone: ["0540525597", "0542749694"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address: "3923 King Fahd Br Rd, حي مشرفة، 7113، Jeddah 23336",
      //       mapLink: "https://maps.app.goo.gl/QFNtDyabkB9cvsA7A",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d231.94915610114592!2d39.1922645!3d21.5395618!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d1074d68a661%3A0xba9a5151faabc412!2z2YjZhiDYs9iq2YjYqCAtINmB2YjZg9izIE9uZSBTdG9wIGZ1Y2hz!5e0!3m2!1sen!2ssa!4v1721754427618!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Jeddah, Umal Qura",
      //       telephone: ["0596465544"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 9:30 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address: "4474 Umm Al Qoura, Al-Safa، JDSC7543، 7543, Jeddah 23455",
      //       mapLink: "https://maps.app.goo.gl/vGnPbnqugSNAg1by7",
      //       embeddedMap:
      //       "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d305.52670642440654!2d39.2161548!3d21.593938!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d17c9ea26e7d%3A0xe179c70c21934ece!2z2YjZhiDYs9iq2YjYqA!5e1!3m2!1sen!2ssa!4v1721830196997!5m2!1sen!2ssa",
      //     },
      //     // More showrooms...
      //   ],
      // },

      {
        name: "Mecca",
        showrooms: [
          {
            locationName: {
              "en":"Mecca, Shawquiya",
              ar:"مكة، الشوقية",
            }, 
            telephone: ["0599463354", "0578321948"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address:
              "7975 3380 Abdullah Ibn Al Abbas St, Al Shoqiyah, Makkah 24351",
            mapLink: "https://maps.app.goo.gl/aNV7ErRCGD5aih2bA",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d779.0132825997018!2d39.796383539805774!3d21.388598834408427!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c21be918a23f07%3A0xbc71cde32b7edf3a!2zRlVDSFMgT05FU1RPUCDZgdmI2YPYsyDZiNmGINiz2KrZiNio!5e1!3m2!1sen!2ssa!4v1721755201692!5m2!1sen!2ssa",
          },

          {
            locationName: {
              "en":"Mecca, Kaki",
              "ar":"مكة، كاكي"
            },
            telephone: ["0538025917", "058-2102366"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["No Break"],
              friday: ["4:00 pm - 10:00 pm"],
            },
            address: "FUCHS ONESTOP KAKIYAH, 9RH4+QJR، الكعكية، 24352،",
            mapLink: "https://maps.app.goo.gl/pfMdSbhrcgjw1PNY7",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d619.8605059257974!2d39.8061083680834!3d21.379390576201587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c21b8ec33da72f%3A0xbcd209c72184fd80!2sFUCHS%20ONESTOP%20KAKIYAH!5e1!3m2!1sen!2ssa!4v1721830301589!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Mecca, Shraya",
              ar:"مكة، شرايا",
            }, 
            telephone: ["0593442769", "0566875285"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address: "FWH9+5XP, Al Khadra, Makkah 24263",
            mapLink: "https://maps.app.goo.gl/BintSsFX1aJ2ZEdSA",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d859.3858490625541!2d39.91991532780972!3d21.47822530556088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c2018dd17eac6d%3A0x480d86a32d0d8318!2zT25lc3RvcCBGdWNocyBzaGFyYXlhINmB2YjaqdizINmI2YYg2LPYqtmI2Kg!5e0!3m2!1sen!2ssa!4v1721755540862!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Taif",
        showrooms: [
          {
            locationName: {
              en: "Taif, Al Jabra Dist",
              ar:"الطائف, حي الجبرة",
            },
            telephone: ["0508573742", "0590448573"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 9:30 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address: "8289, Aljafijif, Taif 26523",
            mapLink: "https://maps.app.goo.gl/kbFACFx7s8VhwGHH6",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d275.60139347541167!2d40.43624911474257!3d21.29353309390992!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15e988fb5f238375%3A0x29ecbb205deba383!2z2YjZhiDYs9iq2YjYqCDYp9mE2LfYp9im2YE!5e1!3m2!1sen!2ssa!4v1721755733571!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Madinah",
        showrooms: [
          {
            locationName: {
              en:"Madinah, Railway",
              ar:"المدينة المنورة، السكك الحديدية",
            }, 
            telephone: ["0572805125", "0577304046"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["12:30 am - 4:30 pm"],
              friday: ["4:00 am - 10:00 pm"],
            },
            address: "Fuchs One Stop FMRR+HQ7, King Fahd, Madinah 42367",
            mapLink: "https://maps.app.goo.gl/PhfVpk4FXHfjaUUt6",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1076.718721984646!2d39.6922241!3d24.4915828!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15bd967de42e0c59%3A0x1b5a95652fdf0e03!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721830364542!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Madinah, Obhur (Al Bandr Dist)",
              ar:"المدينة المنورة، أبحر (حي البندر)",
            }, 
            telephone: ["0569437061", "0577780026"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["No Break"],
              friday: ["4:00 pm - 10:00 pm"],
            },
            address: "فوكس ون ستوب FJRF+VH7, Bani Harithah, Madinah 42313",
            mapLink: "https://maps.app.goo.gl/FADRdrAfgdamNba87",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4306.8644094523!2d39.6213737!3d24.4918888!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15bdbfaf182405e3%3A0x3ccd11826a8f96b8!2z2YHZiNmD2LMg2YjZhiDYs9iq2YjYqA!5e1!3m2!1sen!2ssa!4v1721830480569!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Yanbu",
        showrooms: [
          {
            locationName: {
              en:"Yanbu, Yanbu City",
              ar:"ينبع، مدينة ينبع",
            },
            telephone: ["050-0601706", "056-5955014"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address: "33M6+7WR، طريق الملك فيصل, Al Khurik, Yanbu 46424",
            mapLink: "https://maps.app.goo.gl/BsHk1AjXwd7Nuz5i8",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1080.1862461559283!2d38.0613792!3d24.0833402!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15b9060a61aa7b89%3A0x286f8fe8b194c96d!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721756484249!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Tabuk",
        showrooms: [
          {
            locationName: {
              en:"Tabuk",
              ar:"تبوك",
            }, 
            telephone: ["0597163599"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["12:00 pm - 4:00 pm"],
              friday: ["Closed"],
            },
            address: "6280, Alaziziyah Alqadimah, Tabuk 47912",
            mapLink: "https://maps.app.goo.gl/JjVmpUbruYTFFuKG7",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4163.587098049679!2d36.56796359999999!3d28.3882427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15a9ad0733da3eb5%3A0xa3365cf04d3716cb!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721756699031!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Tabuk",
              ar:"تبوك",
            }, 
            telephone: ["0566351634", "0562375529"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["12:00 pm - 4:00 pm"],
              friday: ["Closed"],
            },
            address: "6280, Alaziziyah Alqadimah, Tabuk 47912",
            mapLink: "https://maps.app.goo.gl/JjVmpUbruYTFFuKG7",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4163.587098049679!2d36.56796359999999!3d28.3882427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15a9ad0733da3eb5%3A0xa3365cf04d3716cb!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721756699031!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Al Jouf",
        showrooms: [
          {
            locationName: {
              en:"Al Jouf Dowmatul Jandal",
              ar:"الجوف دومة الجندل",
            },
            telephone: ["0590831818", "0582134109"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 12:00 pm"],
              break: ["4:00 pm - 10:00 pm"],
              friday: ["Closed"],
            },
            address: "Khazma, Dumat al-Jandal 74644",
            mapLink: "https://maps.app.goo.gl/6RMNoC7W5Dh5bpY27",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3462.3868781257565!2d39.86703412467371!3d29.79536551052144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x150d2fea1b8876ab%3A0x228b085e705ac122!2sFuchs%20one%20stop!5e0!3m2!1sen!2ssa!4v1721757237429!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Sakaka",
        showrooms: [
          {
            locationName: {
              en:"Sakaka",
              ar:"سكاكا",
            }, 
            telephone: ["0581834037"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address: "2068، مخطط القديم، سكاكا 72341 8176, Sakaka 72311",
            mapLink: "https://maps.app.goo.gl/mjP3usGuxRLJwvxr9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d415.73660221895625!2d40.188783435267304!3d29.966065720230837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1572bb336480763b%3A0xbd8fc711cd3b0673!2sOne%20stop%20fuchs!5e0!3m2!1sen!2ssa!4v1721757384363!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Khamis Mushait",
        showrooms: [
          {
            locationName: {
              en:"Khamis Mushait, In front of Halaga",
              ar:"خميس مشيط أمام الحلقة",
            },
            telephone: ["058-1916921"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address: "8PHJ+FV9, Khamis Mushait 62458",
            mapLink: "https://maps.app.goo.gl/T4jzD2YSZoQLHXqW6",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1247.6747693930397!2d42.73158812853601!3d18.328661998916015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15fb5a5b59e31e6d%3A0x479d7c4a9eed6f5c!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721757579882!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Khamis Mushait, Al-Resras dist.",
              ar:"خميس مشيط حي الرصراس.",
            }, 
            telephone: ["0501324053", "0576747985"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["12:00 pm - 4:00 pm"],
              friday: ["Closed"],
            },
            address: "طريق الرصراص, Al Rasras, Khamis Mushait 62441",
            mapLink: "https://maps.app.goo.gl/5iZMCjKsqHbxNafA7",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1248.2797541166983!2d42.7827753!3d18.2446112!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15fb58d45988f419%3A0xa078cf21b88e1031!2sOne%20Stop!5e1!3m2!1sen!2ssa!4v1721757715960!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Khamis Mushait, King Fahad Road",
              ar:"خميس مشيط طريق الملك فهد",
            }, 
            telephone: ["0580105749", "0595406511"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 9:00 pm", ""],
              break: ["12:00 pm - 4:00 pm"],
              friday: ["Closed"],
            },
            address: "7MXQ+P62, Al Mazab, Khamis Mushait 62456",
            mapLink: "https://maps.app.goo.gl/WGDdjX19GcVKPsSb6",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d689.2028137489212!2d42.68819000707093!3d18.2998461535278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15fb50aabb14cba1%3A0x36559018ec7b7ba3!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721758030478!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Najran",
        showrooms: [
          {
            locationName: {
              en:"Najran (King Abdulaziz Road)",
              ar:"نجران (طريق الملك عبد العزيز)",
            }, 
            telephone: ["0582806659", "0552347695"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address: "4852 King Abdulaziz Rd, Najran 66251",
            mapLink: "https://maps.app.goo.gl/MGuSKBtvq3hrPjxeA",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1253.2311715112066!2d44.22073932853515!3d17.542095698956388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15fec2e504af094b%3A0xb066e0103b29e236!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721758191875!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Najran (Hamza Bin Abdul Muttalib Street)",
              ar:"نجران (شارع حمزة بن عبد المطلب)",
            }, 
            telephone: ["0597097080"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address:
              "2733 شارع حمزة بن عبدالمطلب, Almafraq, Al Mafraq 7125, Najran 66255",
            mapLink: "https://maps.app.goo.gl/1zstRtR6Zkzh3q3v8",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1253.1298194345554!2d44.2337765!3d17.5567483!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15fec33b70fcc013%3A0xb262de67f07e27bf!2zRlVDSFMgT05FIFNUT1DZgdmI2YPYsyDZiNmGINiz2KrZiNio!5e1!3m2!1sen!2ssa!4v1721758284171!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Bisha",
        showrooms: [
          {
            locationName: {
              en:"Bisha, Airport Road",
              ar:"بيشة، طريق المطار",
            }, 
            telephone: ["0590485894"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 12:00 pm", ],
              break: ["No Break"],
              friday: ["4:00 pm - 10:00 pm"],
            },
            address: "Fuchs One Stop ALAB7046, 7046 King Saud Rd, 3967, Alkhuzama, Bisha 67711",
            mapLink: "https://maps.app.goo.gl/Viq27KmW9tdpgvEA7",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1235.0575388553077!2d42.619438928538045!3d20.004003698832715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15f02957900bb893%3A0x63e8976185bf6a1f!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721830617431!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Jazan",
        showrooms: [
          {
            locationName: {
              en:"Jazan, Sabea Jizan Road",
              ar:"جازان، طريق سبع جازان",
            }, 
            telephone: ["0598166004", "0502162385"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 10:00 pm"],
              break: ["12:00 pm - 4:20 pm"],
              friday: ["Closed"],
            },
            address: "5JHW+F84, Sabya 85542",
            mapLink: "https://maps.app.goo.gl/qtFNgX2jpKZzJeCv8",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d604.141085519524!2d42.64497747009829!3d17.178714429331073!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15fd512bffe86785%3A0xa9ba48aec8caadc5!2z2YHZiNmD2LMg2YjZhiDYs9iq2YjYqA!5e1!3m2!1sen!2ssa!4v1721758582342!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Jazan, King Abdul Aziz Road",
              ar:"جازان طريق الملك عبد العزيز",
            }, 
            telephone: ["0556501030"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 9:00 pm"],
              break: ["12:00 pm - 4:00 pm"],
              friday: ["Closed"],
            },
            address:
              "6571 King Abdul Aziz Rd، حي الزهور، GGMA2221، 2221, Jazan 82741",
            mapLink: "https://maps.app.goo.gl/8pabXWYr5r3NHYLh9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5030.81428608933!2d42.586765880695154!3d16.883255893085554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1607e3bb3172c149%3A0xbf9dd93efed73c9c!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721758710049!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Al Bahah",
        showrooms: [
          {
            locationName: {
              en:"Al Bahah",
              ar:"الباحة"
            }, 
            telephone: ["0582509223"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address: "ون ستوب 3227 الباحة 65524 8148 65524",
            mapLink: "https://maps.app.goo.gl/GPkYfrXHfRz8MkDV6",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d308.76786291617304!2d41.4617014!3d20.0022307!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15ef4599e2e9de4f%3A0xf893cf86af259300!2z2YjZhiDYs9iq2YjYqA!5e1!3m2!1sen!2ssa!4v1721907366317!5m2!1sen!2ssa",
          },

          {
            locationName: {
              en:"Al Bahah, Al Hamdan Fuel Station",
              ar:"الباحة، محطة الحمدان للوقود",
            }, 
            telephone: ["0582509223", "0596782427"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["12:00 pm - 4:00 pm"],
              friday: ["4:00 pm - 10:00 pm"],
            },
            address: "فوكس ون ستوب 3867، 7052, Aldhafir، 3867، 7052, Al Bahah 65526",
            mapLink: "https://maps.app.goo.gl/9FwPy36WQ6z9P5yy7",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d617.5122705997063!2d41.4770351!3d20.0082082!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15ef4548daf01a81%3A0xdd50416d9d6a155e!2z2YHZiNmD2LMg2YjZhiDYs9iq2YjYqA!5e1!3m2!1sen!2ssa!4v1721907533240!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      // {
      //   name: "Riyadh",
      //   showrooms: [
      //     {
      //       locationName: "Riyadh, Quods Station",
      //       telephone: ["0571657441", "0544936983"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 4:00 pm"],
      //         break: ["No Break"],
      //         friday: ["8:00 am - 4:00 pm"],
      //       },
      //       address:
      //         "2215 King Abdullah Ibn Abdul Aziz Road, Al Quds, Riyadh 13214",
      //       mapLink: "https://maps.app.goo.gl/j7deB3fbETQFKR296",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2387.06192786566!2d46.73863488643761!3d24.759457095048006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0204fb26bda7%3A0xb0a0de518341c949!2sFuchs%20One%20Stop%20FR-3!5e1!3m2!1sen!2ssa!4v1721759234605!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Riyadh, Gharnatah",
      //       telephone: ["0554214997"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["12:00 pm - 1:00 pm"],
      //         friday: ["Closed"],
      //       },
      //       address:
      //         "4094 Al Imam Abdullah Ibn Saud Ibn Abdul Aziz Branch Rd, Ghirnatah, Riyadh 13241",
      //       mapLink: "https://maps.app.goo.gl/FYyWbL3mToymZZhf7",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1074.2187792720995!2d46.7420097!3d24.7819904!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efde40eb2d689%3A0x8f16503f4cda9e4d!2sFuchs%20One%20Stop%20FR-14!5e1!3m2!1sen!2ssa!4v1721803318767!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Riyadh, Attarad",
      //       telephone: ["0593502006", "0532558958"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address:
      //         "JJ3G+3H7, Abbas Ibn Fernass, Al Uraija Al Wusta, Riyadh 12971",
      //       mapLink: "https://maps.app.goo.gl/nEkfHixvsEpBA5SMA",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4303.063034508993!2d46.62455493847228!3d24.60266353241515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1a5a059f999d%3A0x537e0a5f2995c866!2sFuchs%20One%20Stop%20Fr-20!5e1!3m2!1sen!2ssa!4v1721803429943!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Riyadh, Old Al Kharj Road",
      //       telephone: ["0598335255"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["12:00 pm - 4:00 pm"],
      //         friday: ["Closed"],
      //       },
      //       address:
      //         "2256 Al Kharj Rd, Ad Difa, Riyadh 14315 7329 Al Kharj Rd, 7329, Ad Difa, Riyadh 14315",
      //       mapLink: "https://maps.app.goo.gl/KeYVLd9eVXSyTaYG6",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4303.637376378434!2d46.80947279199821!3d24.58595683241687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f09c3d3f94d51%3A0x3970a16bf4a6bf61!2sFuchs%20One%20Stop%20FR-21%20Riyadh!5e1!3m2!1sen!2ssa!4v1721803546631!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Riyadh, Yarmuk",
      //       telephone: ["0581322193", "0571944877"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address: "Abi Jafar Al Mansour St, Ghirnatah, Riyadh 13242",
      //       mapLink: "https://maps.app.goo.gl/CzGVbxuNQZTU3s3n8",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d574.046946646661!2d46.7536821683011!3d24.79729103123756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2eff469131f983%3A0xe63d5d0aa3b872c!2sFUCHS%20ONE%20STOP%20FR%2026!5e1!3m2!1sen!2ssa!4v1721804078936!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Riyadh, Exit 14 & 15 Ring road",
      //       telephone: ["0554377701"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address: "4102، 7728 الطريق الدائري الشرقي الفرعي الرياض 12835 4102",
      //       mapLink: "https://maps.app.goo.gl/g2eJEDLYcex3r4Zg7",
      //       embeddedMap:
      //       "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d298.55280514099445!2d46.77506269357133!3d24.688556568436347!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f06c37282e779%3A0x70d96faf892dae59!2sAldrees!5e1!3m2!1sen!2ssa!4v1721906941622!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Riyadh, 2nd Industrial Area",
      //       telephone: ["0594396635", "0556697869"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 8:00 pm"],
      //         break: ["12:00 pm  - 1:00 pm"],
      //         friday: ["Closed"],
      //       },
      //       address: "One stop Fuchs GVX9+588, New Industrial Area, Riyadh 14331",
      //       mapLink: "https://maps.app.goo.gl/K1CimatobEGSNDcSA",
      //       embeddedMap:
      //       "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1226.6522468827886!2d46.867878903029705!3d24.547176462949!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2fa196429d78e5%3A0xfc6f6112acf71b7c!2sOne%20stop%20Fuchs!5e1!3m2!1sen!2ssa!4v1721907077919!5m2!1sen!2ssa",
      //     },
      //     // More showrooms...
      //   ],
      // },

      {
        name: "Al Kharj",
        showrooms: [
          {
            locationName: {
              en:"Al Kharj",
              ar:"الخرج",
            },
            telephone: ["0590371289 "],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["--"],
              friday: ["Closed"],
            },
            address: "As Safra, Buraydah 52382",
            mapLink: "https://maps.app.goo.gl/HU5gKYXh3XUXUiNv7",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3574.9697309538083!2d43.96407799999999!3d26.359841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDIxJzM1LjQiTiA0M8KwNTcnNTAuNyJF!5e0!3m2!1sen!2ssa!4v1721033035326!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Buraydah",
        showrooms: [
          {
            locationName: {
              en:"Buraydah",
              ar:"بريدة",
            }, 
            telephone: ["0543023704", "0550109648"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["12:00 pm - 4:00 pm"],
              friday: ["Closed"],
            },
            address: "3775 King Abdullah Rd, Al Safra', Buraydah 52382",
            mapLink: "https://maps.app.goo.gl/5dnt7mjqWTdt6NpF9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1060.1576168645395!2d43.9637455!3d26.3596005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x157f5849b9570d39%3A0x178aba7e9adab5d!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721804668919!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Riyadh Al Khabra",
        showrooms: [
          {
            locationName: {
              en:"Riyadh Al Khabra",
              ar:"الرياض الخبراء",
            }, 
            telephone: ["0543023704", "0599455026"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 12:00 pm"],
              break: ["12:00 pm - 4:00 pm"],
              friday: ["Closed"],
            },
            address: "3H83+622, Al Nuzhah, Riyadh Al Khabra 52722",
            mapLink: "https://maps.app.goo.gl/hUdiTFYpFZ1eybc98",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4251.360228894317!2d43.550653829560225!3d26.06551538749681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1582398310993b29%3A0x11a05bf28cb87290!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721804879537!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Hail",
        showrooms: [
          {
            locationName: {
              en:"Hail",
              ar:"حائل",
            }, 
            telephone: ["0580105498"],
            workingHours: {
              saturdayToThursday: ["9:00 am - 10:00 pm"],
              break: ["12:00 pm - 4:00 pm"],
              friday: ["Closed"],
            },
            address: "FMRH+595, An Naqrah, Hail 55431",
            mapLink: "https://maps.app.goo.gl/WHHwWi6d6AJAjS7e9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4198.334868061167!2d41.676626270953456!3d27.49039934754167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x157640aafe9dad93%3A0x40bffcd5aaf46e23!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721807372862!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      // {
      //   name: "Dammam",
      //   showrooms: [
      //     {
      //       locationName: "Dammam, Ibn Khaldon",
      //       telephone: ["0564120380"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address:
      //         "3873 طريق الملك فهد بن عبد العزيز, Al Itisalat, Dammam 32257",
      //       mapLink: "https://maps.app.goo.gl/VhojXpGcgaKk86Js6",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1059.6092505331987!2d50.0858910696248!3d26.419345299601122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49fc8e1e62635b%3A0x8ef9da91fabdfbc9!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721807956900!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Dammam, Al Mothalath",
      //       telephone: ["0591181991", "0597231702"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address:
      //         "C445+738, Al Imam Ali Ibn Abi Talib St, Al Nuzha, Dammam 32252",
      //       mapLink: "https://maps.app.goo.gl/hcfXQBt1JkkTknux8",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1059.7349119974763!2d50.10705676962475!3d26.405665499744032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49fb50974938a5%3A0x68b139c0e77b39c!2sOne%20Stop!5e1!3m2!1sen!2ssa!4v1721808062735!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Dammam, Al Musriquin",
      //       telephone: ["0581534325", "0571565136"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address:
      //         "4199 King Saud Bin Abdulaziz Rd, 4199, Al Dawhah Al Janubiyah, 7628, Dhahran 34455",
      //       mapLink: "https://maps.app.goo.gl/gr55LNdvWVWs8Dd3A",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d530.2932684886043!2d50.17771713199023!3d26.31278166025358!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e73afea23129%3A0xc30b57c22524bbc!2sFuchs%20one%20stop!5e1!3m2!1sen!2ssa!4v1721808285910!5m2!1sen!2ssa",
      //     },

      //     {
      //       locationName: "Dammam, Al Musriquin-2",
      //       telephone: ["058-3340189"],
      //       workingHours: {
      //         saturdayToThursday: ["8:00 am - 10:00 pm"],
      //         break: ["No Break"],
      //         friday: ["Closed"],
      //       },
      //       address: "95QH+R94, Al Safa, Dammam 34222",
      //       mapLink: "https://maps.app.goo.gl/eCAHkiYv8skV6cBo9",
      //       embeddedMap:
      //         "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d529.9416681781064!2d50.1784422!3d26.3894992!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e57ca23e04b1%3A0xc90c493e2fd565c4!2sOne%20Stop%20Fuchs!5e1!3m2!1sen!2ssa!4v1721808355572!5m2!1sen!2ssa",
      //     },
      //     // More showrooms...
      //   ],
      // },

      {
        name: "Al Hofuf",
        showrooms: [
          {
            locationName: {
              en:"Al Hofuf, University Road",
              ar:"الهفوف طريق الجامعة",
            }, 
            telephone: ["0567698010", "0592964254"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 8:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address: "8HPV+3JQ, Prince Fawwaz, Al Khaldiyah, Al Hofuf 36362",
            mapLink: "https://maps.app.goo.gl/Two8uiaZsc6sGLko6",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4277.519939337052!2d49.59253123829552!3d25.335227787544895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e37916c2741458d%3A0xf85e9fd42640cf11!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721808456726!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Al Hofuf, Hafar Al Batin",
        showrooms: [
          {
            locationName: {
              en:"Hafar Al Batin",
              ar:"حفر الباطن"
            }, 
            telephone: ["0509855347"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["12:00 pm - 4:00 pm"],
              friday: ["Closed"],
            },
            address:
              "CXHH+XJ9, Al Baladiyah, Hafar Al Batin 39921",
            mapLink: "https://maps.app.goo.gl/QHsympTELL77JTJPA",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4623.37337854083!2d45.979103!3d28.429906799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fd741865442ed1b%3A0x8d6dc3645c756643!2z2YjZhiDYs9iq2YjYqCDZgdmI2YPYsw!5e1!3m2!1sen!2ssa!4v1721908337248!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Al Wajh",
        showrooms: [
          {
            locationName: {
              en:"Al Wajh",
              ar:"الوجه",
            }, 
            telephone: ["0549396191"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 10:00 pm"],
              break: ["12:00 pm - 1:00 pm"],
              friday: ["Closed"],
            },
            address:
              "Abu Bakr Al Sidiq, حي العزيزية، Al Wajh 48721",
            mapLink: "https://maps.app.goo.gl/DaisUM5wzh9mkDz47",
            embeddedMap:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d500.48989679729294!2d36.461669538158965!3d26.234318134362198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15b1d0f392eed12d%3A0x5c7f436b89f52cc8!2sPetrol%20station!5e1!3m2!1sen!2ssa!4v1721908197787!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },

      {
        name: "Rabigh",
        showrooms: [
          {
            locationName: {
              en:"Rabigh",
              ar:"رابغ",
            }, 
            telephone: ["0580860262", "0592735257"],
            workingHours: {
              saturdayToThursday: ["8:00 am - 11:00 pm"],
              break: ["No Break"],
              friday: ["Closed"],
            },
            address: "8188, Aljud, Rabigh 25726",
            mapLink: "https://maps.app.goo.gl/nkmH2TVWsoxWWcCm9",
            embeddedMap:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2182.204195580047!2d39.03760108996812!3d22.753293897074247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c094485cae5b15%3A0x4ceafe87f06e96e8!2sFuchs%20One%20Stop!5e1!3m2!1sen!2ssa!4v1721807822532!5m2!1sen!2ssa",
          },
          // More showrooms...
        ],
      },
      // More cities...
    ],
  },
  // SERVICE PROVIDERS TAB DATA ENDS
];

export default showroomsData;
