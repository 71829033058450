// import JimnyGLXAugustOfferImage from "../images/offers/jimny-glx-august-offer.jpg";
import SNBSeptemberOfferImage from "../images/offers/suzuki-snb-september-offer-2024.jpg";
import SuzukiSwiftOfferDetailBanner from "../images/offers/suzuki-swift-2025-offer-detail-banner.jpg";

const offers = [

  {
    id: "new-suzuki-swift-2025",
    title: {
      en: "Introducing the All New Suzuki Swift 2025!",
      ar: "سوزوكي سويفت الجديدة كلياً .. تكفي وتوفي",
    },
    description: {
      en: "Get the All New Suzuki Swift 2025 starting from 57,385 SAR including VAT.",
      ar: "احصل الآن على سوزوكي سويفت 2025 الجديدة كلياً بسعر يبدأ من 57,385 ريال شامل الضريبة",
    },

    image: SuzukiSwiftOfferDetailBanner,
    expiryDate: "2024-09-30T00:00:00",
    cities: [
      {
        en: "Riyadh",
        ar: "الرياض",
      },
      {
        en:"Jeddah",
        ar:"جدة",
      },
      {
        en:"Dammam",
        ar:"الدمام",
      },
      {
        en:"Al Ahsa",
        ar:"الأحساء",
      },
      {
        en:"Najran",
        ar:"نجران",
      },
      {
        en:"Onaiza",
        ar:"عنيزة",
      },
      {
        en:"Mecca",
        ar:"مكة المكرمة",
      },
    ],
    vehicles: [""],
    relatedVehicles: [""],
  },

  {
    id: "suzuki-snb-september-offer",
    title: {
      en: "Suzuki SNB September Offer",
      ar: "عروض سوزوكي من البنك الأهلي السعودي خلال سبتمبر",
    },
    description: {
      en: "Get your Suzuki Dzire now starting from 499 SAR monthly installments or get Jimny 5 Doors GLX with only 999 monthly installments, from SNB bank during September 2024",
      ar: "احصل الآن على سيارة ديزاير بقسط شهري يبدأ من 499 ريال أو احصل على جيمني 5 أبواب الفئة الكاملة من سوزوكي فقط بقسط شهري 999 ريال من البنك الأهلي السعودي خلال شهر سبتمبر.",
    },

    image: SNBSeptemberOfferImage,
    expiryDate: "2024-09-30T00:00:00",
    cities: [
      {
        en: "Riyadh",
        ar: "الرياض",
      },
      {
        en:"Jeddah",
        ar:"جدة",
      },
      {
        en:"Dammam",
        ar:"الدمام",
      },
      {
        en:"Al Ahsa",
        ar:"الأحساء",
      },
      {
        en:"Najran",
        ar:"نجران",
      },
      {
        en:"Onaiza",
        ar:"عنيزة",
      },
      {
        en:"Mecca",
        ar:"مكة المكرمة",
      },
    ],
    vehicles: ["Jimny 5 Doors GLX", "Dzire"],
    relatedVehicles: ["jimny-5-doors", "dzire"],
  },
];

export default offers;
