const sparePartsData = {
  dzire: {
    GL: [
      {
        description: "Brake Pad Front",
        price: "322.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },
      {
        description: "Brake Pad Rear / Break Shoe Rear",
        price: "379.50",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },
      {
        description: "Brake Rotor Front",
        price: "294.40",
        arabicDescription: "هوبات الفرامل الأمامية",
      },
      {
        description: "Brake Rotor Rear/Brake Drum Rear",
        price: "251.85",
        arabicDescription: "هوبات الفرامل الخلفية",
      },
      {
        description: "Fan Belt",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },
      {
        description: "Filter Air",
        price: "69.00",
        arabicDescription: "فلتر الهواء",
      },
      {
        description: "Cabin Air Filter",
        price: "109.25",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },
      {
        description: "Filter Oil",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },
      {
        description: "Front Grille",
        price: "718.75",
        arabicDescription: "الشبك الأمامي",
      },
      {
        description: "Head Lamp Left",
        price: "586.50",
        arabicDescription: "مصباح أمامي - يسار",
      },
      {
        description: "Head Lamp Right",
        price: "586.50",
        arabicDescription: "مصباح أمامي - يمين",
      },
      {
        description: "Spark Plug",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },
      {
        description: "Fender Front Left",
        price: "471.50",
        arabicDescription: "رفرف أمامي - يسار",
      },
      {
        description: "Fender Front Right",
        price: "471.50",
        arabicDescription: "رفرف خلفي - يمين",
      },
      {
        description: "Tail Lamp Left",
        price: "471.50",
        arabicDescription: "اسطب خلفي - يسار",
      },
      {
        description: "Tail Lamp Right",
        price: "471.50",
        arabicDescription: "اسطب خلفي - يمين",
      },
      {
        description: "Wiper Blade Left",
        price: "59.80",
        arabicDescription: "ماسحة الزجاج - يسار",
      },
      {
        description: "Wiper Blade Right",
        price: "90.85",
        arabicDescription: "ماسحة الزجاج - يمين",
      },
    ],
    GLX: [
      {
        description: "Brake Pad Front",
        price: "322.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },
      {
        description: "Brake Pad Rear / Break Shoe Rear",
        price: "379.50",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },
      {
        description: "Brake Rotor Front",
        price: "294.40",
        arabicDescription: "هوبات الفرامل الأمامية",
      },
      {
        description: "Brake Rotor Rear/Brake Drum Rear",
        price: "251.85",
        arabicDescription: "هوبات الفرامل الخلفية",
      },
      {
        description: "Fan Belt",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },
      {
        description: "Filter Air",
        price: "69.00",
        arabicDescription: "فلتر الهواء",
      },
      {
        description: "Cabin Air Filter",
        price: "109.25",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },
      {
        description: "Filter Oil",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },
      {
        description: "Front Grille",
        price: "718.75",
        arabicDescription: "الشبك الأمامي",
      },
      {
        description: "Head Lamp Left",
        price: "586.50",
        arabicDescription: "مصباح أمامي - يسار",
      },
      {
        description: "Head Lamp Right",
        price: "586.50",
        arabicDescription: "مصباح أمامي - يمين",
      },
      {
        description: "Spark Plug",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },
      {
        description: "Fender Front Left",
        price: "471.50",
        arabicDescription: "رفرف أمامي - يسار",
      },
      {
        description: "Fender Front Right",
        price: "471.50",
        arabicDescription: "رفرف خلفي - يمين",
      },
      {
        description: "Tail Lamp Left",
        price: "471.50",
        arabicDescription: "اسطب خلفي - يسار",
      },
      {
        description: "Tail Lamp Right",
        price: "471.50",
        arabicDescription: "اسطب خلفي - يمين",
      },
      {
        description: "Wiper Blade Left",
        price: "59.80",
        arabicDescription: "ماسحة الزجاج - يسار",
      },
      {
        description: "Wiper Blade Right",
        price: "90.85",
        arabicDescription: "ماسحة الزجاج - يمين",
      },
    ],
  },

  baleno: {
    "2019-2022 GL": [
      {
        description: "BRAKE PAD FRONT",
        price: "322.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },
      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "330.05",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },
      {
        description: "BRAKE ROTOR FRONT",
        price: "347.30",
        arabicDescription: "هوبات الفرامل الأمامية",
      },
      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "251.85",
        arabicDescription: "هوبات الفرامل الخلفية",
      },
      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },
      {
        description: "FILTER AIR",
        price: "69.00",
        arabicDescription: "فلتر الهواء",
      },
      {
        description: "CABIN AIR FILTER",
        price: "136.85",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },
      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },
      {
        description: "FRONT GRILLE",
        price: "189.75",
        arabicDescription: "الشبك الأمامي",
      },
      {
        description: "HEAD LAMP LEFT",
        price: "563.50",
        arabicDescription: "مصباح أمامي - يسار",
      },
      {
        description: "HEAD LAMP RIGHT",
        price: "564.65",
        arabicDescription: "مصباح أمامي - يمين",
      },
      {
        description: "SPARK PLUG",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },
      {
        description: "FENDER FRONT LEFT",
        price: "219.65",
        arabicDescription: "رفرف أمامي - يسار",
      },
      {
        description: "FENDER FRONT RIGHT",
        price: "248.40",
        arabicDescription: "رفرف خلفي - يمين",
      },
      {
        description: "TAIL LAMP LEFT",
        price: "394.45",
        arabicDescription: "اسطب خلفي - يسار",
      },
      {
        description: "TAIL LAMP RIGHT",
        price: "401.35",
        arabicDescription: "اسطب خلفي - يمين",
      },
      {
        description: "WIPER BLADE LEFT",
        price: "59.80",
        arabicDescription: "ماسحة الزجاج - يسار",
      },
      {
        description: "WIPER BLADE RIGHT",
        price: "90.85",
        arabicDescription: "ماسحة الزجاج - يمين",
      },
      {
        description: "REAR WIPER BLADE",
        price: "96.60",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
    "2019-2022 GLX": [
      {
        description: "BRAKE PAD FRONT",
        price: "322.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },
      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "330.05",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },
      {
        description: "BRAKE ROTOR FRONT",
        price: "347.30",
        arabicDescription: "هوبات الفرامل الأمامية",
      },
      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "251.85",
        arabicDescription: "هوبات الفرامل الخلفية",
      },
      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },
      {
        description: "FILTER AIR",
        price: "69.00",
        arabicDescription: "فلتر الهواء",
      },
      {
        description: "CABIN AIR FILTER",
        price: "136.85",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },
      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },
      {
        description: "FRONT GRILLE",
        price: "189.75",
        arabicDescription: "الشبك الأمامي",
      },
      {
        description: "HEAD LAMP LEFT",
        price: "2,300.00",
        arabicDescription: "مصباح أمامي - يسار",
      },
      {
        description: "HEAD LAMP RIGHT",
        price: "2,147.05",
        arabicDescription: "مصباح أمامي - يمين",
      },
      {
        description: "SPARK PLUG",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },
      {
        description: "FENDER FRONT LEFT",
        price: "267.95",
        arabicDescription: "رفرف أمامي - يسار",
      },
      {
        description: "FENDER FRONT RIGHT",
        price: "267.95",
        arabicDescription: "رفرف خلفي - يمين",
      },
      {
        description: "TAIL LAMP LEFT",
        price: "795.80",
        arabicDescription: "اسطب خلفي - يسار",
      },
      {
        description: "TAIL LAMP RIGHT",
        price: "825.70",
        arabicDescription: "اسطب خلفي - يمين",
      },
      {
        description: "WIPER BLADE LEFT",
        price: "59.80",
        arabicDescription: "ماسحة الزجاج - يسار",
      },
      {
        description: "WIPER BLADE LEFT",
        price: "90.85",
        arabicDescription: "ماسحة الزجاج - يسار",
      },
      {
        description: "REAR WIPER BLADE",
        price: "96.60",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
    "2023 GL": [
      {
        description: "BRAKE PAD FRONT",
        price: "322.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "216.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "230.00",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "226.55",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "69.00",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "109.25",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "299.00",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "967.15",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,129.30",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "23.00",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "293.25",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "293.25",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "670.45",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "722.20",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "59.80",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "90.85",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "REAR WIPER BLADE",
        price: "96.60",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
    "2023 GLX": [
      {
        description: "BRAKE PAD FRONT",
        price: "322.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "216.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "230.00",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "226.55",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "69.00",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "109.25",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "305.90",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,875.65",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,829.65",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "23",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "293.25",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "293.25",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "830.30",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "830.30",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "59.80",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "90.85",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "REAR WIPER BLADE",
        price: "102.35",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
  },

  ciaz: {
    GL: [
      {
        description: "BRAKE PAD FRONT",
        price: "437.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "285.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "171.35",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "256.45",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "69.00",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "111.55",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FUEL FILTER",
        price: "97.75",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "677.35",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,001.65",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,001.65",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "313.95",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "267.95",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "207.00",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "221.95",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "89.70",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "70.15",
        arabicDescription: "ماسحة الزجاج - يمين",
      },
    ],
    GLX: [
      {
        description: "BRAKE PAD FRONT",
        price: "437.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "285.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "171.35",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "256.45",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "69.00",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "111.55",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FUEL FILTER",
        price: "97.75",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "677.35",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "3,458.05",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "3,705.30",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "313.95",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "267.95",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "479.55",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "541.65",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "89.70",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "70.15",
        arabicDescription: "ماسحة الزجاج - يمين",
      },
    ],
  },

  "swift-2024": {
    GL: [
      {
        description: "BRAKE PAD FRONT",
        price: "577.30",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "308.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "385.25",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "487.60",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "110.40",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "85.10",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "40.25",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "320.85",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,459.35",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,355.85",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "742.90",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "690.00",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "1,846.90",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "1,988.35",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "120.75",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "126.50",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "72.45",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
    GLX: [
      {
        description: "BRAKE PAD FRONT",
        price: "577.30",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "308.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "385.25",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "487.60",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "110.40",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "85.10",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "40.25",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "320.85",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "4,835.75",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "4,835.75",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "742.90",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "690.00",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "1,846.90",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "1,988.35",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "120.75",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "126.50",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "72.45",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
  },

  fronx: {
    GL: [
      {
        description: "BRAKE PAD FRONT",
        price: "322.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "379.50",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "247.25",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "226.55",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "50.60",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "54.05",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "109.25",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "510.60",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,265.00",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,265.00",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "189.75",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "346.15",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "346.15",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "623.30",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "623.30",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "59.80",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "90.85",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "102.35",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
    GLX: [
      {
        description: "BRAKE PAD FRONT",
        price: "322.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "379.50",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "247.25",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "226.55",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "50.60",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "54.05",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "109.25",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "963.70 ",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,265.00",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,265.00",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "189.75 ",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "346.15",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "346.15",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "623.30",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "623.30",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "59.80",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "90.85",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "102.35",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
  },

  "grand-vitara": {
    "2WD GL": [
      {
        description: "BRAKE PAD FRONT",
        price: "501.40",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "349.60",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "217.35",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "301.30",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "50.60",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "54.05",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "113.85",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "1,100.55",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,653.70",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,653.70",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "189.75",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "317.40",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "317.40",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "569.25",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "569.25",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "60.95",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "57.50",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "149.50",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],

    "2WD GLX": [
      {
        description: "BRAKE PAD FRONT",
        price: "501.40",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "349.60",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "217.35",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "301.30",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "50.60",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "54.05",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "113.85",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "1,100.55",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,653.70",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,653.70",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "189.75",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "317.40",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "317.40",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "569.25",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "569.25",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "60.95",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "57.50",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "149.50",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],

    "2WD GLX + SR": [
      {
        description: "BRAKE PAD FRONT",
        price: "501.40",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "349.60",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "217.35",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "301.30",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "50.60",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "54.05",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "113.85",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "1,100.55",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,653.70",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,653.70",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "189.75",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "317.40",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "317.40",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "569.25",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "569.25",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "60.95",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "57.50",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "149.50",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],

    "4WD GLX + SR": [
      {
        description: "BRAKE PAD FRONT",
        price: "501.40",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "349.60",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "217.35",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "301.30",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "50.60",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "54.05",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "113.85",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "1,100.55",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,653.70",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,653.70",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "189.75",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "317.40",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "317.40",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "569.25",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "569.25",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "60.95",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "57.50",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "149.50",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
  },

  ertiga: {
    "2019-2022 GL": [
      {
        description: "BRAKE PAD FRONT",
        price: "572.70",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "331.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "266.80",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "225.40",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "45.00",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "109.25",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "894.74",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,037.30",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,037.30",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "23.00",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "286.35",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "264.50",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "487.60",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "454.25",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "88.55",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "70.15",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "108.10",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],

    "2019-2022 GLX": [
      {
        description: "BRAKE PAD FRONT",
        price: "572.70",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "331.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "266.80",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "225.40",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "45.00",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "109.25",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "894.74",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,037.30",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,037.30",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "23.00",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "286.35",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "264.50",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "487.60",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "454.25",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "88.55",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "70.15",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "108.10",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],

    "2023-2024 GL": [
      {
        description: "BRAKE PAD FRONT",
        price: "572.70",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "331.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "266.80",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "225.40",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "69.00",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "109.25",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "1,351.25",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,037.30",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,037.30",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "23.00",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "282.90",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "282.90",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "487.60",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "454.25",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "88.55",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "70.15",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "108.10",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],

    "2023-2024 GLX": [
      {
        description: "BRAKE PAD FRONT",
        price: "572.70",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "331.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "266.80",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "225.40",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "69.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "69.00",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "109.25",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "1,351.25",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,037.30",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,037.30",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "23.00",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "282.90",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "282.90",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "487.60",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "454.25",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "88.55",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "70.15",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "108.10",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
  },

  jimny: {
    GL: [
      {
        description: "BRAKE PAD FRONT",
        price: "483.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "382.95",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "572.70",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "668.15",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "48.30",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "98.90",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "78.20",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "40.25",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "1,014.30",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1031.56",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1031.56",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "461.15",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "435.85",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "600.30",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "600.30",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "143.75",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "143.75",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "119.60",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],

    GLX: [
      {
        description: "BRAKE PAD FRONT",
        price: "483.00",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "382.95",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "572.70",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "668.15",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "48.30",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "98.90",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "78.20",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "40.25",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "1,014.30",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "4694.30",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "4694.30",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "461.15",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "435.85",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "600.30",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "600.30",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "143.75",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "143.75",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "REAR WIPER BLADE",
        price: "119.60",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
  },

  "jimny-5-doors": {
    GL: [
      {
        description: "BRAKE PAD FRONT",
        price: "623.30",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "338.10",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "403.65",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "371.45",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "46.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "70.15",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "36.80",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "40.25",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "862.50",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "322.00",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "322.00",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "313.95",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "313.95",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "322.00",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "322.00",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "70.15",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "70.15",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "105.80",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],

    GLX: [
      {
        description: "BRAKE PAD FRONT",
        price: "623.30",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "338.10",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "403.65",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "371.45",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "46.00",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "70.15",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "36.80",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "40.25",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "862.50",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "1,978.00",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "1,978.00",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "40.25",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "313.95",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "313.95",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "322.00",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "322.00",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "70.15",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "70.15",
        arabicDescription: "ماسحة الزجاج - يمين",
      },

      {
        description: "REAR WIPER BLADE",
        price: "105.80",
        arabicDescription: "ماسحة الزجاج الخلفي",
      },
    ],
  },

  carry: {
    "Pickup Single Cab": [
      {
        description: "BRAKE PAD FRONT",
        price: "374.90",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "278.30",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "211.60",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "347.30",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "60.95",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "102.35",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "683.10",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "901.60",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "841.80",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "29.90",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "534.75",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "560.05",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "461.15",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "496.80",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "65.55",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "65.55",
        arabicDescription: "ماسحة الزجاج - يمين",
      },
    ],
  },

  eeco: {
    "VAN 1.2L MT": [
      {
        description: "BRAKE PAD FRONT",
        price: "271.40",
        arabicDescription: "بطانات الفرامل الأمامية",
      },

      {
        description: "BRAKE PAD REAR / BRAKE SHOE REAR",
        price: "216.20",
        arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
      },

      {
        description: "BRAKE ROTOR FRONT",
        price: "200.10",
        arabicDescription: "هوبات الفرامل الأمامية",
      },

      {
        description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
        price: "397.90",
        arabicDescription: "هوبات الفرامل الخلفية",
      },

      {
        description: "FAN BELT",
        price: "29.90",
        arabicDescription: "سير المروحة",
      },

      {
        description: "FILTER AIR",
        price: "59.80",
        arabicDescription: "فلتر الهواء",
      },

      {
        description: "CABIN AIR FILTER",
        price: "64.40",
        arabicDescription: "فلتر هواء المقصورة (المكيف)",
      },

      {
        description: "FILTER OIL",
        price: "28.75",
        arabicDescription: "فلتر الزيت",
      },

      {
        description: "FRONT GRILLE",
        price: "89.70",
        arabicDescription: "الشبك الأمامي",
      },

      {
        description: "HEAD LAMP LEFT",
        price: "442.75",
        arabicDescription: "مصباح أمامي - يسار",
      },

      {
        description: "HEAD LAMP RIGHT",
        price: "454.25",
        arabicDescription: "مصباح أمامي - يمين",
      },

      {
        description: "SPARK PLUG",
        price: "52.90",
        arabicDescription: "شمعات الإشعال (بواجي)",
      },

      {
        description: "FENDER FRONT LEFT",
        price: "236.90",
        arabicDescription: "رفرف أمامي - يسار",
      },

      {
        description: "FENDER FRONT RIGHT",
        price: "236.90",
        arabicDescription: "رفرف خلفي - يمين",
      },

      {
        description: "TAIL LAMP LEFT",
        price: "133.40",
        arabicDescription: "اسطب خلفي - يسار",
      },

      {
        description: "TAIL LAMP RIGHT",
        price: "132.25",
        arabicDescription: "اسطب خلفي - يمين",
      },

      {
        description: "WIPER BLADE LEFT",
        price: "73.60",
        arabicDescription: "ماسحة الزجاج - يسار",
      },

      {
        description: "WIPER BLADE RIGHT",
        price: "73.60",
        arabicDescription: "ماسحة الزجاج - يمين",
      },
    ],
  },

  vitara: [
    {
      description: "BRAKE PAD FRONT",
      price: "872.85",
      arabicDescription: "بطانات الفرامل الأمامية",
    },
    {
      description: "BRAKE PAD REAR / BRAKE SHOE REAR",
      price: "967.15",
      arabicDescription: "بطانات/أقمشة الفرامل الخلفية",
    },
    {
      description: "BRAKE ROTOR FRONT",
      price: "569.25",
      arabicDescription: "هوبات الفرامل الأمامية",
    },
    {
      description: "BRAKE ROTOR REAR/BRAKE DRUM REAR",
      price: "430.10",
      arabicDescription: "هوبات الفرامل الخلفية",
    },
    {
      description: "FAN BELT",
      price: "63.25",
      arabicDescription: "سير المروحة",
    },
    {
      description: "FILTER AIR",
      price: "136.85",
      arabicDescription: "فلتر الهواء",
    },
    {
      description: "CABIN AIR FILTER",
      price: "54.05",
      arabicDescription: "فلتر هواء المقصورة (المكيف)",
    },
    {
      description: "FILTER OIL",
      price: "40.25",
      arabicDescription: "فلتر الزيت",
    },
    {
      description: "FRONT GRILLE",
      price: "1369.65",
      arabicDescription: "الشبك الأمامي",
    },
    {
      description: "HEAD LAMP LEFT",
      price: "5393.50",
      arabicDescription: "مصباح أمامي - يسار",
    },
    {
      description: "HEAD LAMP RIGHT",
      price: "5008.25",
      arabicDescription: "مصباح أمامي - يمين",
    },
    {
      description: "SPARK PLUG",
      price: "33.35",
      arabicDescription: "شمعات الإشعال (بواجي)",
    },
    {
      description: "FENDER FRONT LEFT",
      price: "724.50",
      arabicDescription: "رفرف أمامي - يسار",
    },
    {
      description: "FENDER FRONT RIGHT",
      price: "724.50",
      arabicDescription: "رفرف خلفي - يمين",
    },
    {
      description: "TAIL LAMP LEFT",
      price: "1,531.8",
      arabicDescription: "اسطب خلفي - يسار",
    },
    {
      description: "TAIL LAMP RIGHT",
      price: "1,531.8",
      arabicDescription: "اسطب خلفي - يمين",
    },
    {
      description: "WIPER BLADE LEFT",
      price: "194.35",
      arabicDescription: "ماسحة الزجاج - يسار",
    },
    {
      description: "WIPER BLADE RIGHT",
      price: "169.05",
      arabicDescription: "ماسحة الزجاج - يمين",
    },
    {
      description: "REAR WIPER BLADE",
      price: "101.20",
      arabicDescription: "ماسحة الزجاج الخلفي",
    },
  ],
};

export default sparePartsData;
