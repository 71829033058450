import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./OfferCard.css";
import CountdownTimer from "../../services/CountdownTimer/CountdownTimer";

const OfferCard = ({ offer }) => {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const targetDate = new Date(offer.expiryDate);

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./OfferCard-ar.css");
    } else {
      import("./OfferCard.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  // Format the target date
  const formattedDate = targetDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });

  
  const hideTimer = offer.id === "be-first-to-know";

  return (
    <Link to={`/offers/${offer.id}`}>
      <Card className="offer-card">
        <Card.Img
          variant="top"
          src={offer.image}
          alt={offer.title[currentLanguage]}
        />
        <Card.Body className="card-body-align">
          <Card.Title className="offer-title">
            {offer.title[currentLanguage]}
          </Card.Title>
          <Card.Text className="offer-desc">
            {offer.description[currentLanguage]}
          </Card.Text>
          <div className="link-align">
            <Link to={`/offers/${offer.id}`}>{t("More Info")}</Link>
            <i
              className={`${
                i18n.language === "ar"
                  ? "jam jam-arrow-left"
                  : "jam jam-arrow-right"
              }`}
            ></i>
          </div>
        </Card.Body>

        {!hideTimer && (
          <div
            className={`${
              i18n.language === "ar"
                ? "offer-timer-badge offer-badge-right"
                : "offer-timer-badge offer-badge-right"
            }`}
          >
            <p>
              {t("Ends on")} {formattedDate}
            </p>
          </div>
        )}
      </Card>
    </Link>
  );
};

export default OfferCard;
