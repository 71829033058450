import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";
import vehicles from "../../hooks/vehicles"; // Assuming you already have vehicles data here
import "./VehicleVariant.css";

// Arrow Components for Slider
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "30px",
        color: "black",
        textAlign: "center",
        marginTop: "1rem",
      }}
      onClick={onClick}
    >
      <i className="jam jam-arrow-right"></i>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "30px",
        color: "black",
        textAlign: "center",
        marginTop: "1rem",
      }}
      onClick={onClick}
    >
      <i className="jam jam-arrow-left"></i>
    </div>
  );
};

const VehicleVariant = ({ vehicleId }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const vehicle = vehicles.find((v) => v.id === vehicleId);

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleVariant-ar.css");
    } else {
      import("./VehicleVariant.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const validVariants = vehicle?.variants?.filter((variant) => variant.items.length > 0);

  if (!validVariants || validVariants.length === 0) return null;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: currentLanguage === "ar" ? <PrevArrow /> : <NextArrow />,
    prevArrow: currentLanguage === "ar" ? <NextArrow /> : <PrevArrow />,
    rtl: currentLanguage === "ar",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="section-spacing">
      <Container className="vehicle-variant">
        <Row className="align-items-center">
          <Col md={12} lg={9}>
            <Slider {...settings}>
              {vehicle.variants.map((variant, index) => {
                // Apply dual-tone logic ONLY for Swift
                let variantImage;

                if (vehicle.id === "swift") {
                  // Check if the variant is dual-tone by its name (only for Swift)
                  const isDualTone = variant.name.includes("Dual Tone");

                  // Use SwiftWhiteDualToneBannerImage for Dual Tone variants
                  variantImage = isDualTone
                    ? vehicle.colors.find(color => color.customName.en === "Arctic White Pearl + Midnight Black Pearl")?.image // White Dual Tone
                    : vehicle.colors.find(color => color.customName.en === "Luster Blue Pearl Metallic")?.image; // Non-dual-tone
                } else {
                  // For other vehicles, just use the standard vehicle image
                  variantImage = vehicle.image;
                }

                return (
                  <div key={index} className="variant-card">
                    <img
                      src={variantImage}
                      alt={variant.name}
                      className="variant-image"
                    />
                    <div className="variant-data-align">
                      <h4>{variant.name}</h4>
                      <p>SAR {variant.price}</p>
                    </div>
                    <ul className="vehicle-variant-list">
                      {variant.items.map((item, idx) => (
                        <li key={idx}>{item[currentLanguage]}</li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </Slider>
          </Col>
          <Col md={12} lg={3}>
            <div className="static-content">
              <h2>{t("GL vs. GLX")}</h2>
              <p>{t("Explore the unique features and enhancements that set the GL and GLX models apart. Dive into our detailed comparison to find the perfect fit for your driving needs.")}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VehicleVariant;
