import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import "./OfferFormOne.css";

const OfferFormOne = ({ cities, vehicles, offerName, offerURL }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  // Determine if the "Select Vehicle" field should be hidden
  const isSpecialOffer = offerName === "Introducing the All New Suzuki Swift 2025!" || offerName === "سوزوكي سويفت الجديدة كلياً .. تكفي وتوفي";


  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      gender: "",
      city: "",
      vehicle: isSpecialOffer ? "Suzuki Swift 2025" : "Suzuki Swift 2025",
      message: "",
      contactByEmail: false,
      contactByPhone: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required(t("First Name is required"))
        .matches(/^[\u0600-\u06FFa-z A-Z]+$/, t("Only characters are allowed"))
        .min(3, t("First Name should be at least 3 characters long")),
      lastName: Yup.string()
        .required(t("Last Name is required"))
        .matches(/^[\u0600-\u06FFa-z A-Z]+$/, t("Only characters are allowed"))
        .min(3, t("Last Name should be at least 3 characters long")),
      contactNumber: Yup.string()
        .required(t("Contact Number is required"))
        .matches(
          /^05\d{8}$/,
          t("Contact Number should start with 05 and be 10 digits long")
        ),
      email: Yup.string()
        .required(t("Email is required"))
        .email(t("Invalid email format")),
      gender: Yup.string().required(t("Gender is required")),
      city: Yup.string().required(t("City is required")),
      vehicle: Yup.string().test(
        "vehicleTest",
        t("Vehicle is required"),
        function (value) {
          if (isSpecialOffer) return true; // Skip validation for special offer
          return !!value; // Validate vehicle for other offers
        }
      ),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("First_name", values.firstName);
      formData.append("Last_name", values.lastName);
      formData.append("Phone", values.contactNumber);
      formData.append("Email", values.email);
      formData.append("Gender", values.gender);
      formData.append("City", values.city);
      formData.append("Car", values.vehicle);
      formData.append("Comments", values.message);
      formData.append(
        "EmailPreference",
        values.contactByEmail ? t("Prefer to be contacted by Email") : ""
      );
      formData.append(
        "ContactNumberPreference",
        values.contactByPhone
          ? t("Prefer to be contacted by Contact Number")
          : ""
      );
      formData.append("Offer_URL", offerURL);
      formData.append("Offer_name", offerName);

      try {
        const response = await axios.post(
          "https://najeebapi.nhcops.com/public/api/Get_offer",
          formData
        );
        if (response.status === 200 && response.data === "done") {
          setIsSubmitted(true);
          formik.resetForm();
        } else if (response.data === "already offer") {
          alert("You already applied for this offer with the provided number.");
        } else {
          alert("Unexpected response: " + response.data);
        }
      } catch (error) {
        setSubmissionError("Error submitting form.");
        console.error("Submission Error:", error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  if (isSubmitted) {
    return (
      <div className="success-message text-center">
        <h3>{t("Submitted Successfully!")}</h3>
        <p>{t("Our team will reach you as soon as possible.")}</p>
      </div>
    );
  } else if (submissionError) {
    return (
      <div className="error-message text-center">
        <h3>{t("Submission Failed!")}</h3>
        <p>{t("Please try again after sometime.")}</p>
      </div>
    );
  }

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          {/* First Name */}
          <Col md={6}>
            <Form.Group controlId="formFirstName">
              <Form.Label className="brand-label">
                {t("First Name")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {/* Last Name */}
          <Col md={6}>
            <Form.Group controlId="formLastName">
              <Form.Label className="brand-label">
                {t("Last Name")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {/* Contact Number */}
          <Col md={6}>
            <Form.Group controlId="formContactNumber">
              <Form.Label className="brand-label">
                {t("Contact Number")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="text"
                name="contactNumber"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.contactNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contactNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {/* Email */}
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label className="brand-label">
                {t("Email Address")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Control
                className="brand-input"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {/* Gender */}
          <Col md={6}>
            <Form.Group controlId="formGender">
              <Form.Label className="brand-label">
                {t("Select Gender")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.gender}
              >
                <option value="">{t("Select Gender")}</option>
                <option value="Male">{t("Male")}</option>
                <option value="Female">{t("Female")}</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.gender}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {/* City */}
          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label className="brand-label">
                {t("Select City")}
                <span className="required-mark">*</span>
              </Form.Label>
              <Form.Select
                className="brand-input"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.city}
              >
                <option value="">{t("Select City")}</option>
                {cities.map((city, index) => (
                  <option key={index} value={city[currentLanguage]}>
                    {city[currentLanguage]}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.city}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {/* Vehicle Dropdown - Only show if not a special offer */}
          {!isSpecialOffer && (
            <Col md={12}>
              <Form.Group controlId="formVehicle">
                <Form.Label className="brand-label">
                  {t("Select Vehicle")}
                  <span className="required-mark">*</span>
                </Form.Label>
                <Form.Select
                  className="brand-input"
                  name="vehicle"
                  value={formik.values.vehicle}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.vehicle}
                >
                  <option value="">{t("Select Vehicle")}</option>
                  {vehicles.map((vehicle, index) => (
                    <option key={index} value={vehicle}>
                      {vehicle}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.vehicle}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}

          {/* Contact Preferences */}
          <Col md={6}>
            <Form.Group controlId="formContactByEmail">
              <Form.Check
                className="brand-label"
                type="checkbox"
                label={t("Prefer to be contacted by Email")}
                name="contactByEmail"
                checked={formik.values.contactByEmail}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formContactByPhone">
              <Form.Check
                className="brand-label"
                type="checkbox"
                label={t("Prefer to be contacted by Contact Number")}
                name="contactByPhone"
                checked={formik.values.contactByPhone}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Col>

          {/* Message */}
          <Col md={12}>
            <Form.Group controlId="formMessage">
              <Form.Label className="brand-label">{t("Message")}</Form.Label>
              <Form.Control
                className="brand-input"
                as="textarea"
                rows={3}
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Submit Button */}
        <div className="d-grid gap-2 col-6 mx-auto">
          <Button
            className="form-brand-btn"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner animation="border" size="sm" />
            ) : (
              t("Submit")
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default OfferFormOne;
