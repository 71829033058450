import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-bootstrap";
import TeaserOfferVideo from "../../videos/offer-video/teaser-video.mp4";
import SuzukiSwift2025Banner from "../../images/offers/suzuki-swift-2025-banner.png"
import HomeBannerImage from "../../images/home-banner.png";
import HomeBannerImageTwo from "../../images/home-banner-2.jpg";
import HomeBannerImageThree from "../../images/home-banner-3.png";
import HomeBannerImageFour from "../../images/home-banner-4.png";
import HomeBannerImageFive from "../../images/home-banner-5.png";
import HomeBannerImageSix from "../../images/home-banner-6.png";

import './HomeBanner.css';

const HomeBanner = () => {
  const { t, i18n } = useTranslation();
  const videoRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [interval, setInterval] = useState(6000); // Default interval for image slides

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./HomeBanner-ar.css");
    } else {
      import("./HomeBanner.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  useEffect(() => {
    if (activeIndex === 0 && videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setInterval(12000);
    } else if (videoRef.current) {
      videoRef.current.pause();
      setInterval(6000);
    }
  }, [activeIndex]);

  const handleSlideChange = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <Carousel
      fade
      className="home-banner-carousel"
      onSelect={handleSlideChange}
      interval={interval}
    >
      {/* <Carousel.Item>
        <div className="home-banner">
          <video
            ref={videoRef}
            src={TeaserOfferVideo}
            loop
            muted
            controls={false}
          />
          <a href="/offers/be-first-to-know">
            <div className="discover-button">
              <i className="jam jam-arrow-up-right text-white"></i>
              <span>{t("Let's Discover")}</span>
            </div>
          </a>
        </div>
      </Carousel.Item> */}

      <Carousel.Item>
        <div className="home-banner">
          <img src={SuzukiSwift2025Banner} alt={t("Suzuki Swift 2025")} />
          <div className="home-banner-content">
            <h2 className="banner-title-style text-white">{t("The All New")}</h2>
            <h2 className="text-white">{t("Suzuki Swift")}</h2>
          </div>

          <a href="/offers/new-suzuki-swift-2025">
            <div className="discover-button">
              <i className="jam jam-arrow-up-right text-white"></i>
              <span>{t("Let's Discover")}</span>
            </div>
          </a>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className="home-banner">
          <img src={HomeBannerImage} alt={t("Suzuki Grand Vitara")} />
          <div className="home-banner-content">
            <h2 className="banner-title-style">{t("The All New")}</h2>
            <h2>{t("Suzuki Grand Vitara")}</h2>
          </div>

          <a href="/vehicles/grand-vitara">
            <div className="discover-button">
              <i className="jam jam-arrow-up-right text-white"></i>
              <span>{t("Let's Discover")}</span>
            </div>
          </a>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className="home-banner">
          <img src={HomeBannerImageTwo} alt={t("Suzuki Fronx")} />
          <div className="home-banner-content">
            <h2 className="banner-title-style">{t("Vibe Different")}</h2>
            <h2>{t("Suzuki Fronx")}</h2>
          </div>

          <a href="/vehicles/fronx">
            <div className="discover-button">
              <i className="jam jam-arrow-up-right text-white"></i>
              <span>{t("Let's Discover")}</span>
            </div>
          </a>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className="home-banner">
          <img src={HomeBannerImageThree} alt={t("Suzuki Jimny 5-Door")} />
          <div className="home-banner-content">
            <h2 className="banner-title-style text-white">{t("Rugged. Iconic.")}</h2>
            <h2 className="text-white">{t("Suzuki Jimny 5-Door")}</h2>
          </div>

          <a href="/vehicles/jimny-5-doors">
            <div className="discover-button">
              <i className="jam jam-arrow-up-right text-white"></i>
              <span>{t("Let's Discover")}</span>
            </div>
          </a>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className="home-banner">
          <img src={HomeBannerImageFour} alt={t("Suzuki Jimny 5-Door")} />
          <div className="home-banner-content">
            <h2 className="banner-title-style text-white">{t("Compact")}</h2>
            <h2 className="text-white">{t("Suzuki Jimny")}</h2>
          </div>

          <a href="/vehicles/jimny">
            <div className="discover-button">
              <i className="jam jam-arrow-up-right text-white"></i>
              <span>{t("Let's Discover")}</span>
            </div>
          </a>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className="home-banner">
          <img src={HomeBannerImageFive} alt={t("Suzuki Ciaz")} />
          <div className="home-banner-content">
            <h2 className="banner-title-style">{t("Up Your Game")}</h2>
            <h2>{t("Suzuki Ciaz")}</h2>
          </div>

          <a href="/vehicles/ciaz">
            <div className="discover-button">
              <i className="jam jam-arrow-up-right text-white"></i>
              <span>{t("Let's Discover")}</span>
            </div>
          </a>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className="home-banner">
          <img src={HomeBannerImageSix} alt={t("Suzuki Baleno")} />
          <div className="home-banner-content">
            <h2 className="banner-title-style">{t("The All-New")}</h2>
            <h2>{t("Suzuki Baleno")}</h2>
          </div>

          <a href="/vehicles/baleno">
            <div className="discover-button">
              <i className="jam jam-arrow-up-right text-white"></i>
              <span>{t("Let's Discover")}</span>
            </div>
          </a>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeBanner;
